const {
  SOURCE_MTG_CLB,
  SOURCE_SKT,
} = require('./../../sourceList')
const {
  BACKGROUND_AGENT_HARPERS,
  BACKGROUND_CITY_WATCH,
  BACKGROUND_FAR_TRAVELLER,
  BACKGROUND_INHERITOR,
  BACKGROUND_KNIGHT_OF_THE_ORDER,
  BACKGROUND_UTHGARDT_TRIBE_MEMBER,
  BACKGROUND_WATERDHAVIAN_NOBLE,
} = require('./../../backgroundIdList')
const {
  City_watch_SCAG_data,
  Far_traveler_SCAG_data,
  Humans_Waterdhavian_SCAG_data,
  Inheritor_SCAG_data,
  Knight_of_the_order_SCAG_data,
  Uthgardt_tribe_member_SCAG_data,
} = require('./../commonImageCollection')

const Uthgardt_Elk_Tribe_SKT_img = require('./../../../images/backgrounds/uthgardt_elk_tribe.png')

const Far_Traveler_MTG_CLB_img = require('./../../../images/backgrounds/far-traveler-mtg_clb.jpg')
const Roving_harper_MTG_CLB_img = require('./../../../images/backgrounds/roving_harper_mtg_clb.webp')

module.exports = {
  [BACKGROUND_AGENT_HARPERS]: {
    src: Roving_harper_MTG_CLB_img,
    text: 'Бродячая Арфистка',
    source: SOURCE_MTG_CLB,
  },
  [BACKGROUND_CITY_WATCH]: City_watch_SCAG_data,
  [BACKGROUND_FAR_TRAVELLER]: [
    Far_traveler_SCAG_data,
    {
      src: Far_Traveler_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [BACKGROUND_INHERITOR]: Inheritor_SCAG_data,
  [BACKGROUND_KNIGHT_OF_THE_ORDER]: Knight_of_the_order_SCAG_data,
  [BACKGROUND_UTHGARDT_TRIBE_MEMBER]: [
    Uthgardt_tribe_member_SCAG_data,
    {
      src: Uthgardt_Elk_Tribe_SKT_img,
      text: 'Утгардтское племя Лося',
      source: SOURCE_SKT,
    },
  ],
  [BACKGROUND_WATERDHAVIAN_NOBLE]: Humans_Waterdhavian_SCAG_data,
}
