import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import {FILTER_TYPE_INPUT} from '@/constants/filterTypes'

export default [
  {
    label: 'Введите название предыстории или её текст',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
