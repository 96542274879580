import React from 'react'

import backgroundList from '@/constants/backgroundList'
import Catalog from '@/components/Catalog'

import generateBackgroundPageUrlById from "@/utils/generateBackgroundPageUrlById"

import filterListOriginal from './constants/filterList'

const BackgroundCatalogContainer = () => {
  const list = backgroundList.filter(({isReady}) => isReady)

  return (
    <Catalog
      pageUrlGenerator={generateBackgroundPageUrlById}
      pageTitle='Каталог предысторий персонажей'
      itemNameList={list}
      itemList={list}
      filterListOriginal={filterListOriginal}
    />
  )
}

export default BackgroundCatalogContainer
