import React from 'react'

import DescriptionList from '@/components/DescriptionList'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import Note from '@/components/Note'
import PcEquipment from '@/components/PcEquipment'
import PcProficiency from '@/components/PcProficiency'
import SourceInfo from '@/components/SourceInfo'

import generateTextLinks from '@/utils/generateTextLinks'

import BackgroundImageList from './components/BackgroundImageList'

import './BackgroundStyles.less'

const BackgroundComponent = (
  {
    descriptionMain,
    descriptionOther,
    equipment,
    header,
    id,
    note,
    proficiency,
    subHeader,
    source,
  },
) => (
  <section className='Background'>
    <section className='Background_infoBlock'>
      <HeaderBlock
        className='Background_header'
        subHeader={subHeader}
      >
        {header}
      </HeaderBlock>
      {
        descriptionMain
          ? (
            <Markdown className='Background_description'>
              {generateTextLinks(descriptionMain)}
            </Markdown>
          )
          : null
      }
      <SourceInfo
        className='Background_source'
        source={source}
        useFullName
      />
    </section>
    <section className='Background_infoBlock'>
      <PcProficiency
        proficiency={proficiency}
        showEmpty={false}
      />
      <SourceInfo
        className='Background_source'
        source={source}
        useFullName
      />
    </section>
    <section className='Background_infoBlock'>
      <PcEquipment
        equipment={equipment}
        preText='Вы начинаете со следующим снаряжением'
        showBuyVariant={false}
      />
      <SourceInfo
        className='Background_source'
        source={source}
        useFullName
      />
    </section>
    <Note
      note={note}
      className='Background_note'
    />
    {
      descriptionOther
        ? <DescriptionList description={descriptionOther} />
        : null
    }
    <BackgroundImageList backgroundId={id}/>
  </section>
)

export default BackgroundComponent
