import React from 'react'

import Background from '@/components/Background'
import BackgroundCatalog from '@/components/BackgroundCatalog'

import backgroundImageCollection from '@/constants/images/backgroundImageCollection'
import {backgroundCollection} from '@/constants/backgroundList'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Предыстории',
  description: 'Каталог всех предысторий в Dungeons & Dragons',
  img: null,
}

const BackgroundPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={backgroundCollection}
    ItemComponent={Background}
    itemImageCollection={backgroundImageCollection}
    ListComponent={BackgroundCatalog}
    {...props}
  />
)

export default BackgroundPageTemplate
