import React from 'react'

import { backgroundCollection } from '@/constants/backgroundList'

import generateNameStr from '@/utils/generateNameStr'
import splitDescription from '@/utils/splitDescription'

import BackgroundComponent from './BackgroundComponent'

const BackgroundContainer = ({ id }) => {
  if (id) {
    const background = backgroundCollection[id]

    if (background) {
      const {header, subHeader} = generateNameStr(background)
      const [descriptionMain, descriptionOther] = background.description
        ? splitDescription(background.description)
        : ['', null]

      return (
        <BackgroundComponent
          header={header}
          subHeader={subHeader}
          descriptionMain={descriptionMain.text || descriptionMain}
          descriptionOther={descriptionOther}
          {...background}
        />
      )
    }
  }

  return null
}

export default BackgroundContainer
