const {
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {
  BACKGROUND_ACOLYTE,
  BACKGROUND_CHARLATAN,
  BACKGROUND_CRIMINAL,
  BACKGROUND_ENTERTAINER,
  BACKGROUND_FOLK_HERO,
  BACKGROUND_GUILD_ARTISAN,
  BACKGROUND_HERMIT,
  BACKGROUND_NOBLE,
  BACKGROUND_OUTLANDER,
  BACKGROUND_SAGE,
  BACKGROUND_SOLDIER,
  BACKGROUND_URCHIN,
} = require('./../../backgroundIdList')
const {
  Criminal_Background_PHB_data,
  Entertainer_Background_PHB_data,
  Elf_sun_female_PHB_data,
  Guild_Artisan_Background_PHB_data,
  Hermit_Background_PHB_data,
  Outlander_Background_PHB_data,
  Soldier_Background_PHB_data,
} = require('./../commonImageCollection')
const Noble_MTG_CLB_img = require('./../../../images/backgrounds/noble-mtg_clb.jpg')
const Outlander_MTG_CLB_img = require('./../../../images/backgrounds/outlander-mtg_clb.jpg')
const Criminal_MTG_CLB_img = require('./../../../images/backgrounds/criminal-mtg_clb.jpg')
const Folk_hero_MTG_CLB_img = require('./../../../images/backgrounds/folk_hero.jpg')
const Acolyte_of_bahamut_MTG_CLB_img = require('./../../../images/backgrounds/acolyte_of_bahamut_mtg_clb.jpg')
const Cloakwood_hermit_MTG_CLB_img = require('./../../../images/backgrounds/cloakwood_hermit.jpeg')
const Shameless_charlatan_MTG_CLB_img = require('./../../../images/backgrounds/shameless-charlatan.webp')
const Urchin_MTG_CLB_img = require('./../../../images/backgrounds/urchin_mtg_clb.webp')
const Guild_artisan_MTG_CLB_img = require('./../../../images/backgrounds/guild_artisan_mtg_clb.webp')

module.exports = {
  [BACKGROUND_CHARLATAN]: {
    src: Shameless_charlatan_MTG_CLB_img,
    text: 'Бесстыжий шарлатан',
    source: SOURCE_MTG_CLB,
  },
  [BACKGROUND_CRIMINAL]: [
    Criminal_Background_PHB_data,
    {
      src: Criminal_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    }
  ],
  [BACKGROUND_ACOLYTE]: {
    src: Acolyte_of_bahamut_MTG_CLB_img,
    text: 'Прислужник Бахамута',
    source: SOURCE_MTG_CLB,
  },
  [BACKGROUND_ENTERTAINER]: Entertainer_Background_PHB_data,
  [BACKGROUND_FOLK_HERO]: {
    src: Folk_hero_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  [BACKGROUND_GUILD_ARTISAN]: [
    Guild_Artisan_Background_PHB_data,
    {
      src: Guild_artisan_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [BACKGROUND_HERMIT]: [
    Hermit_Background_PHB_data,
    {
      src: Cloakwood_hermit_MTG_CLB_img,
      text: 'Отшельник в Скрытолесье',
      source: SOURCE_MTG_CLB,
    },
  ],
  [BACKGROUND_NOBLE]: {
    src: Noble_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  [BACKGROUND_OUTLANDER]: [
    Outlander_Background_PHB_data,
    {
      src: Outlander_MTG_CLB_img,
      source: SOURCE_MTG_CLB,
    },
  ],
  [BACKGROUND_SAGE]: Elf_sun_female_PHB_data,
  [BACKGROUND_SOLDIER]: Soldier_Background_PHB_data,
  [BACKGROUND_URCHIN]: {
    src: Urchin_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
}
