import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import backgroundImageCollection from '@/constants/images/backgroundImageCollection'
import {backgroundCollection} from '@/constants/backgroundList'

const BackgroundImageListContainer = ({ backgroundId }) => {
  if (backgroundId) {
    const background = backgroundCollection[backgroundId]
    const imageList = backgroundImageCollection[backgroundId]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...background}
        />
      )
    }

    return null
  }

  return null
}

BackgroundImageListContainer.propTypes = {
  backgroundId: PropTypes.string.isRequired,
}

export default BackgroundImageListContainer
